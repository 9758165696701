<form class="flex flex-col w-full d"
      [formGroup]="dateForm">
    <mat-form-field class="w-full daterange xs:min-w-full">
        <mat-label>Daterange</mat-label>
        <input type="text" matInput
               ngxDaterangepickerMd
               readonly
               [locale]="{applyLabel: 'ok', format: 'YYYY-MM-DD'}"
               startKey="from"
               endKey="to"
               (change)="changeFormatDate($event)"
               [formControlName]="'daterange_obj'"
               name="daterange"/>
        <button type="button" class="mat-focus-indicator mat-icon-button mat-button-base ngx-daterangepicker-action"
                (click)="openDatepicker($event)">
            <svg viewBox="0 0 24 24" width="24px" height="24px" fill="currentColor" focusable="false"
                 class="mat-datepicker-toggle-default-icon  ngx-daterangepicker-action">
                <path class="ngx-daterangepicker-action"
                        d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
                </path>
            </svg>
            <div class="mat-button-focus-overlay"></div>
        </button>
    </mat-form-field>
</form>

