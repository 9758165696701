import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Input, Output, EventEmitter
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

import {WalletsService} from '../../../modules/main/wallets/wallets.service';

@Component({
    selector: 'app-wallets-select',
    templateUrl: './wallets-select.component.html',
    styleUrls: ['./wallets-select.component.scss']
})
export class WalletsSelectComponent implements OnInit, OnDestroy {

    @Input() form;
    @Input() field = 'id';
    @Input() userId = null;
    @Input() required;
    @Input() showClearIcon;
    @Input() loadSubscriber = null;

    @Output() changed?: any = new EventEmitter();

    showAutoLoading = false;
    isItemsScroll = false;
    isResetItems = false;
    errorLoadRoles = false;
    packagesParams = {
        address: '',
        page: ''
    };

    items$: Observable<any>;

    // Private
    private _unsubscribeAll = new Subject<any>();

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param _walletsService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _walletsService: WalletsService
    ) {
    }

    ngOnInit(): void {
        if (this.loadSubscriber) {
            this.loadSubscriber
                .subscribe((id) => {
                    this.userId = id;
                    this.getItems(true);
                });
        }
        this.getItems(false);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        if (this.isResetItems) {
            this._walletsService.resetItems();
        }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    autocompleteItems(value: string): void {
        this.isItemsScroll = false;
        this.packagesParams.address = value;
        this.getItems(true);
    }

    scrollItems(event: any): void {
        if (event && this.packagesParams.page) {
            this.isItemsScroll = true;
            this.getItems(true);
        }
    }

    getItems(isLoad = true): void {
        this.items$ = this._walletsService.items$;
        let params = {...{}, ...this.packagesParams};
        if (this._walletsService?.items?.length && !isLoad) {
            this.showAutoLoading = false;
            return;
        }
        this.showAutoLoading = true;
        this._changeDetectorRef.markForCheck();
        if (!params.address) {
            delete params.address;
        }
        if (!params.page) {
            delete params.page;
        } else {
            this.isResetItems = true;
        }
        if (this.userId) {
            params = {...params, ...{user_id: this.userId}};
        }
        this._walletsService.getItems(params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this.showAutoLoading = false;
                this.errorLoadRoles = false;
                const page = ++res.data.current_page <= res.data.last_page ? res.data.current_page : null;
                page ? this.packagesParams.page = page : delete this.packagesParams.page;
                this._changeDetectorRef.markForCheck();
            }, error => {
                this.showAutoLoading = false;
                this.errorLoadRoles = true;
                this._changeDetectorRef.markForCheck();
            });
    }

    selected(data): void {
        this.changed.next(data);
    }
}
