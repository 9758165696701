import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../../../core/auth/auth.service';

@Component({
    selector: 'app-fab-menu',
    templateUrl: './fab-menu.component.html',
})
export class FabMenuComponent {

    @Output() scrolled: any = new EventEmitter();

    fabButtonsRandom: any[] = [
        {
            id: 1,
            icon: 'support',
            function: this.openUrl,
            tooltip: 'Support',
            tooltipPosition: 'left',
            url: 'https://support.dluckyslots.com/',
        },
        {
            id: 2,
            icon: 'account_balance_wallet',
            tooltip: 'Packages',
            tooltipPosition: 'left',
            function: this.navigateUrl,
            url: '/packages',
        },
        {
            id: 3,
            icon: 'shopping_bag',
            tooltip: 'Products',
            tooltipPosition: 'left',
            function: this.navigateUrl,
            url: '/products',
        }
    ];

    constructor(private _router: Router,
                private _authService: AuthService) {
        this.checkAuth();
        this.checkSlugPage();
    }

    checkAuth(): void {
        const isAuth = this._authService._authenticated;
        const fabButtonLogin = {
            id: 4,
            icon: 'account_circle',
            tooltip: 'Sign in',
            tooltipPosition: 'left',
            function: this.navigateUrl,
            url: '/sign-in',
        };
        if (!isAuth) {
            this.fabButtonsRandom.push(fabButtonLogin);
        }
    }

    checkSlugPage(): void {
        const splitRoute = this._router.url.split('/');
        const isSlug = splitRoute && splitRoute.length ? splitRoute[1] === 'pages' ||
            (splitRoute[1] === 'products' && splitRoute[2] === 'details') : false;
        const fabButtonSlug = {
            id: 5,
            icon: 'add_shopping_cart',
            tooltip: 'Scroll to pay form',
            tooltipPosition: 'left',
            function: this.scroll
        };
        if (isSlug) {
            this.fabButtonsRandom.push(fabButtonSlug);
        }
    }

    toggle(id: number): void {
        const item = this.fabButtonsRandom.find(el => el.id === id);
        const that = this;
        item.function(that, item.url);
    }

    openUrl(that, url: string): void {
        window.open(url, '_blank');
    }

    navigateUrl(that, url: string): void {
        that._router.navigateByUrl(url);
    }

    scroll(that: any): void {
        that.scrolled.emit(true);
    }

}
