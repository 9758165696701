import {
    Component,
    Input,
    OnInit,
    ChangeDetectorRef, ViewChild,
} from '@angular/core';
import moment from 'moment';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';


@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

    @Input() dateForm;

    @ViewChild(DaterangepickerDirective, {static: false}) pickerDirective: DaterangepickerDirective;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    openDatepicker(e): void {
        e.preventDefault();
        this.pickerDirective.open();
    }

    changeFormatDate(event: any): void {
        if (!event.from || !event.to) {
            return;
        }
        this.dateForm.get('from').setValue(moment(event.from).format('YYYY-MM-DD'));
        this.dateForm.get('to').setValue(moment(event.to).format('YYYY-MM-DD'));
    }

    checkValidationPicker(): void {
        this.dateForm.get('timer_expires_at').markAsTouched();
        this.dateForm.get('timer_expires_at').updateValueAndValidity();
        this._changeDetectorRef.markForCheck();
    }

}
