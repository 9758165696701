import {Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {ViewImageComponent} from '../view-image/view-image.component';
import {NotificationUtilsService} from '../../../core/utils/notification-utils.service';
import {FormUtilsService} from '../../../core/utils/form-utils.service';
import {NgxImageCompressService} from 'ngx-image-compress';


@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
    private url = environment.apiUrl;

    @Input() form;
    @Input() multi = false;
    @Input() typeAccept = 'image/*';
    @Input() label = 'Upload your profile picture.';
    @Input() field = 'cover';
    @Input() id = 'customFileId';
    @Input() showDelete = false;
    @Input() labelStaticWidth = false;
    @Input() withoutMarge = false;
    @Input() showDeleteIcon = true;

    @Output() upload = new EventEmitter();

    maxSize = 4194304;

    fileUrl;
    loader = false;
    disabled = false;
    compressionFiles = [];

    constructor(private _changeDetectorRef: ChangeDetectorRef,
                private _httpClient: HttpClient,
                private _notificationUtilsService: NotificationUtilsService,
                public _formUtilsService: FormUtilsService,
                private dialog: MatDialog,
                private imageCompress: NgxImageCompressService) {
        this.compressionFiles = [];
    }

    ngOnInit(): void {
        if (this.form.get(this.field)?.value && !this.multi) {
            this.fileUrl = this.form.get(this.field).value;
        }
    }


    compressFile(file, fileUrl): void {
        this.imageCompress.compressFile(fileUrl, -1).then(
            result => {
                const blob = this._formUtilsService.dataURItoBlob(result);
                const imageFile = new File([blob], file.name, {type: file.type});
                if (imageFile?.size && imageFile.size > this.maxSize) {
                    this.form.get(this.field).setErrors({'serverError': 'Max upload size is 4MB'});
                    this.loader = false;
                    this._changeDetectorRef.markForCheck();
                    return;
                }
                this.fileUrl = result;
                this.uploadFile(imageFile);
                this._changeDetectorRef.markForCheck();
            });
    }

    saveImage(event: any): void {
        if (this.multi) {
            return this.saveImages(event);
        }
        const file = (event.target as HTMLInputElement).files[0];
        this.loader = true;
        const render = new FileReader();
        render.onload = (e) => {
            this.compressFile(file, e.target.result);

        };
        render.readAsDataURL(file);
    }

    deleteImage(): void {
        this.fileUrl = '';
        this.form.get(this.field).setValue('');
        this._changeDetectorRef.markForCheck();
    }

    uploadFile(file: any): void {
        const data = new FormData();
        data.append('file', file);
        this._httpClient.post<any>(this.url + '/api/uploads/images', data).subscribe(res => {
                if (res?.data?.file?.url?.length) {
                    const fileUrl = res.data.file.url;
                    this.form.get(this.field).patchValue(fileUrl);
                    this.form.get(this.field).updateValueAndValidity();
                } else {
                    this.fileUrl = '';
                    const message = 'Error loading image';
                    this._notificationUtilsService.showActionNotification(message, 0);
                }
                this.loader = false;
                this._changeDetectorRef.markForCheck();
            }, error => {
                this.loader = false;
                this.fileUrl = '';
                const message = 'Error loading image';
                this._notificationUtilsService.showActionNotification(message, 0);
                this._changeDetectorRef.markForCheck();
            }
        );
    }

    viewImage(img: string): void {
        this.dialog.open(ViewImageComponent, {
            data: img
        });
    }

    saveImages(event: any): void {
        const files = (event.target as HTMLInputElement).files;
        this.loader = true;
        const filesCount = [...Array.from(files)];
        Array.from(files).map((file, i) => {
            const render = new FileReader();
            render.onload = (e) => {
                filesCount.splice(0, 1);
                this.compressFiles(file, e.target.result, !filesCount.length);
            };
            render.readAsDataURL(file);
        });
    }

    compressFiles(file, fileUrl, last?: boolean): void {
        this.imageCompress.compressFile(fileUrl, -1).then(
            result => {
                const blob = this._formUtilsService.dataURItoBlob(result);
                const imageFile = new File([blob], file.name, {type: file.type});
                if (imageFile?.size && imageFile.size > this.maxSize) {
                    const message = `${file.name} Exceeds the max upload size. Max upload size is 4MB`;
                    this._notificationUtilsService.showActionNotification(message, 0);
                    return;
                }
                if (last) {
                    this.compressionFiles.push(imageFile);
                    this.uploadFiles(this.compressionFiles);
                } else {
                    this.compressionFiles.push(imageFile);
                }
                this._changeDetectorRef.markForCheck();
            });
    }

    uploadFiles(files: any[]): void {
        const data = new FormData();
        const uploadFiles = [];
        files.map(file => {
            data.append(file.name, file);
        });
        this._httpClient.post<any>(this.url + '/api/uploads/images', data).subscribe(res => {
                if (res?.data) {
                    Object.values(res.data).map(value => {
                        uploadFiles.push(value['url']);
                    });
                    this.upload.emit(uploadFiles);
                } else {
                    const message = 'Error loading image';
                    this._notificationUtilsService.showActionNotification(message, 0);
                }
                this.loader = false;
                this._changeDetectorRef.markForCheck();
            }, error => {
                this.loader = false;
                const message = 'Error loading image';
                this._notificationUtilsService.showActionNotification(message, 0);
                this._changeDetectorRef.markForCheck();
            }
        );
    }

}
