<ng-container *ngIf="!errorLoadPackages">
    <div class="flex w-full">
        <app-custom-select [selectForm]="form"
                           [formField]="field"
                           [label]="'Package'"
                           [isInfinityScroll]="true"
                           (infiniteScroll)="scrollItems($event)"
                           (autocomplete)="autocompleteItems($event)"
                           [isItemsScroll]="isItemsScroll"
                           [showAutoLoading]="showAutoLoading"
                           (changed)="selected($event)"
                           [isAutocomplete]="true"
                           [items]="items$"
                           [required]="false"
                           [showClearIcon]="showClearIcon"
                           [multi]="multi">
        </app-custom-select>
        <ng-container *ngIf="!(items$ | async)?.length && showAutoLoading">
            <div class="flex align-items-center">
                <mat-progress-spinner
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
        </ng-container>
    </div>
</ng-container>
