import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';

import {StorageUtilsService} from '../../../core/utils/storage.utils.service';

@Injectable({
    providedIn: 'root'
})
export class PackagesService {
    // Private
    private _packages: BehaviorSubject<any[] | null>;
    private _currencies: BehaviorSubject<any[] | null>;

    private _pagination: BehaviorSubject<number | null>;
    private _paginationFaqs: BehaviorSubject<number | null>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param _storageUtilsService
     */
    constructor(
        private _httpClient: HttpClient,
        private _storageUtilsService: StorageUtilsService
    ) {
        // Set the private defaults
        this._packages = new BehaviorSubject([]);
        this._currencies = new BehaviorSubject([]);

        this._pagination = new BehaviorSubject(null);
        this._paginationFaqs = new BehaviorSubject(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get packages$(): Observable<any[]> {
        return this._packages.asObservable();
    }

    get packages(): any[] {
        return this._packages.getValue();
    }

    get currencies(): any[] {
        return this._currencies.getValue();
    }

    get currencies$(): Observable<any[]> {
        return this._currencies.asObservable();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<number> {
        return this._pagination.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resetItems(): void {
        this._packages.next([]);
    }

    getUrl(id?: string | number, addToUrl?: string): string {
        let url = `${this._storageUtilsService.urlDependingValue()}/packages`;
        if (id) {
            url = url + `/${id}`;
        }
        if (addToUrl) {
            url = url + `/${addToUrl}`;
        }
        return url;
    }

    getItems(params?: any): Observable<any> {
        return this._httpClient.get<any>(this.getUrl(), {
            params: params
        }).pipe(
            tap((res) => {
                this._packages.next(res.data.data);
                this._pagination.next(res.data.total);
            })
        );
    }

    getMeItems(params?: any): Observable<any> {
        return this._httpClient.get<any>(`${this._storageUtilsService.urlDependingValue(3)}/packages`, {
            params: params
        }).pipe(
            tap((res) => {
                this._packages.next(res.data.data);
                this._pagination.next(res.data.total);
            })
        );
    }

    getCurrencies(params): Observable<any> {
        return this._httpClient.get<any>(this._storageUtilsService.urlWithBackend() + '/payments/currencies', {
            params
        }).pipe(
            tap((res) => {
                this._currencies.next(res.data.data);
            })
        );
    }

    /**
     * Get
     */
    getById(id: number): Observable<any> {
        return this._httpClient.get<any>(this.getUrl(id)).pipe(
            map((res) => res.data)
        );
    }

    getRelatedById(id: number, params = {}): Observable<any> {
        return this._httpClient.get<any>(this.getUrl(id, 'related'), {
            params
        }).pipe(
            map((res) => res.data)
        );
    }

    updateRelativeProducts(id: number, data: any): Observable<any> {
        return this._httpClient.post<any>(this.getUrl(id, 'relate_products/update'), data);
    }

    updateRelativePackages(id: number, data: any): Observable<any> {
        return this._httpClient.post<any>(this.getUrl(id, 'relate_packages/update'), data);
    }

    /**
     * Create empty user
     */
    createEmpty(): Observable<any> {
        return this.packages$.pipe(
            take(1),
            map((packages) => {
                const newItem: any = {
                    id: null,
                    name: '',
                    isCreating: true,
                };
                //
                // // Update the packages with the new user
                this._packages.next([newItem, ...packages]);

                this.updatePagination(+1);

                // // Return the new user
                return newItem;
            })
        );
    }

    create(items: any): Observable<any> {
        return this.packages$.pipe(
            take(1),
            switchMap(packages =>
                this._httpClient.post<any>(this.getUrl(), items).pipe(
                    map((response) => {
                            const created = response.data;
                            // Find the index of the deleted user
                            const index = packages.findIndex(item => item.isCreating);
                            created['packageforms'] = [];
                            created['packageforms'].push(created.package_form);
                            // update the empty user
                            packages[index] = created;

                            // Update the packages
                            this._packages.next(packages);

                            // Return the deleted status
                            return created;
                        },
                        error => {
                            return error;
                        })
                ))
        );
    }

    update(data: any, pack: any): Observable<any> {
        return this.packages$.pipe(
            take(1),
            switchMap(packages =>
                this._httpClient.patch<any>(this.getUrl(data.id), data).pipe(
                    map((response) => {
                            const updated = response.data;
                            // Find the index of the updated user
                            const index = packages.findIndex(item => item.id === data.id);

                            updated['packageforms'] = [];
                            updated['packageforms'].push(updated.package_form);
                            // Update the user
                            packages[index] = {...pack, ...updated};

                            // Update the packages
                            this._packages.next(packages);

                            // Return the updated user
                            return updated;
                        }
                    )
                )),
            catchError(error => {
                return throwError(error);
            }),
        );
    }

    delete(id: number): Observable<boolean> {
        return this.packages$.pipe(
            take(1),
            switchMap(packages =>
                this._httpClient.delete<any>(this.getUrl(id)).pipe(
                    map((bol) => {

                        const index = packages.findIndex(item => item.id === id);

                        packages.splice(index, 1);

                        this._packages.next(packages);

                        this.updatePagination(-1);
                        return true;
                    })
                ))
        );
    }

    cancelCreate(): Observable<boolean> {
        return this.packages$.pipe(
            take(1),
            map((packages) => {

                // Find the index of the deleted user
                const index = packages.findIndex(item => item.isCreating);

                // Delete the user
                packages.splice(index, 1);

                // Update the packages
                this._packages.next(packages);

                this.updatePagination(-1);

                // Return the deleted status
                return true;
            })
        );
    }

    updatePagination(val: number): void {
        this.pagination$.pipe(take(1)).subscribe(res => {
            const data = res + val;
            this._pagination.next(data);
        });
    }

}
