import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {ViewImageComponent} from './view-image.component';

@NgModule({
    declarations: [
        ViewImageComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatInputModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        ViewImageComponent
    ]
})
export class ViewImageModule {
}
