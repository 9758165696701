import {Inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';

import {ZLoggerService} from '../loggers/zlogger.service';
import moment from 'moment';
import {WINDOW} from './window.provider';

@Injectable()
export class Interceptor implements HttpInterceptor {
    /**
     * Constructor
     *
     * @param {AuthService} _authService
     * @param _zLoggerService
     * @param window
     */
    constructor(
        private _authService: AuthService,
        private _zLoggerService: ZLoggerService,
        @Inject(WINDOW) private window: Window
    ) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // const clientId = localStorage.getItem('clientId') || environment.clientId;
        const domain = this.window.location.hostname;

        const headerConfig = {
            'x-api-domain': domain,
            'Accept': 'application/json'
        };

        // if (clientId){
        //     headerConfig['x-api-key'] = clientId;
        // }

        if (this._authService.accessToken) {
            headerConfig['Authorization'] = 'Bearer ' + this._authService.accessToken;
        }

        req = req.clone({
            setHeaders: headerConfig
        });
        const startTime = moment().format('YYYY-MM-DD HH:mm:ss');
        return next.handle(req).pipe(
            tap(res => {
                if (res?.type !== 0) {
                    const response = {
                        status: res['status'] ? res['status'] : null,
                        response: res['body'] ? res['body'] : null,
                    };
                    const data = { ...response, ...req, ...{start_time: startTime, end_time: moment().format('YYYY-MM-DD HH:mm:ss')}};
                    const message = res && res['message'];
                    this._zLoggerService.setTelemetry('network', 'info', message, data);
                }
            }),
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    location.reload();
                }
                const response = {
                    status: error['status'] ? error['status'] : null,
                    response: error['error'] ? error['error'] : null,
                    error: error['error'] ? error['error'] : null,
                    name: error['name'] ? error['name'] : null,
                    message: error['message'] ? error['message'] : null,
                    traceback: error['trace'] ? error['trace'] : null,
                };
                const data = { ...response, ...req, ...{start_time: startTime, end_time: moment().format('YYYY-MM-DD HH:mm:ss')}};
                return throwError(data);
            })
        );
    }
}
