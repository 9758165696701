import {Layout} from 'app/layout/layout.types';

import {PERMISSIONS} from '../../shared/contstants/permissions.const';

// Theme type
export type Theme = 'auto' | 'dark' | 'light';

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
    layout: Layout;
    theme: Theme;
}

/**
 * Default configuration for the entire application. This object is used by
 * 'ConfigService' to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * 'ConfigService'.
 */
export const appConfig: AppConfig = {
    layout: 'centered',
    theme: 'dark'
};

export const backendNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/backend/dashboard',
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'mat_outline:people_outline',
        link: '/backend/users',
        permission: PERMISSIONS.USERS.VIEW
    },
    {
        id: 'wallets',
        title: 'Wallets',
        type: 'basic',
        icon: 'mat_outline:account_balance_wallet',
        link: '/backend/wallets',
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'basic',
        icon: 'mat_outline:sticky_note',
        link: '/backend/pages',
    },
    {
        id: 'packages',
        title: 'Packages',
        type: 'basic',
        icon: 'mat_outline:web',
        link: '/backend/packages',
    },
    {
        id: 'products',
        title: 'Products',
        type: 'basic',
        icon: 'mat_outline:shopping_bag',
        link: '/backend/products',
    },
    {
        id: 'assets',
        title: 'Assets',
        type: 'basic',
        icon: 'mat_outline:web_asset',
        link: '/backend/assets',
    },
    {
        id: 'layouts',
        title: 'Layouts',
        type: 'collapsable',
        icon: 'mat_outline:style',
        children: [
            {
                id: 'layouts-global',
                title: 'Global',
                type: 'basic',
                icon: 'mat_outline:line_style',
                link: '/backend/layouts/global',
            }, {
                id: 'layouts-packages',
                title: 'Packages',
                type: 'basic',
                icon: 'mat_outline:web',
                link: '/backend/layouts/packages',
            },
            {
                id: 'layouts-package',
                title: 'Package',
                type: 'basic',
                icon: 'mat_outline:account_balance_wallet',
                link: '/backend/layouts/package',
            },
            {
                id: 'layouts-products',
                title: 'Products',
                type: 'basic',
                icon: 'mat_outline:shopping_bag',
                link: '/backend/layouts/products',
            },
            {
                id: 'layouts-product',
                title: 'Product',
                type: 'basic',
                icon: 'mat_outline:shopping_basket',
                link: '/backend/layouts/product',
            },
            {
                id: 'layouts-nft',
                title: 'Nft',
                type: 'basic',
                icon: 'mat_outline:web_asset',
                link: '/backend/layouts/nft',
            },
            {
                id: 'layouts-nft-details',
                title: 'Nft details',
                type: 'basic',
                icon: 'mat_outline:work_outline',
                link: '/backend/layouts/nft-details',
            },
        ]
    },
    {
        id: 'payments',
        title: 'Payments',
        type: 'basic',
        icon: 'mat_outline:payments',
        link: '/backend/payments'
    },
    {
        id: 'notifications',
        title: 'Notifications',
        type: 'basic',
        icon: 'mat_outline:notifications',
        link: '/backend/notifications',
        permission: PERMISSIONS.NOTIFICATIONS.VIEW
    },
    {
        id: 'roles',
        title: 'Roles',
        type: 'basic',
        icon: 'mat_outline:manage_accounts',
        link: '/backend/roles',
        permission: PERMISSIONS.ROLES.VIEW
    },
    {
        id: 'configs',
        title: 'Configs',
        type: 'basic',
        icon: 'mat_outline:list',
        link: '/backend/configs',
    },
];

export const publicNavigation: any[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        link: '/home',
        externalLink: '/home',
        meta: {
            notLogin: true
        }
    },
    {
        id: 'me-packages',
        title: 'Packages',
        type: 'basic',
        link: '/me-packages',
        meta: {
            isCustomer: true
        }
    },
    {
        id: 'packages-not-login',
        title: 'Packages',
        type: 'basic',
        link: '/packages',
        externalLink: '/packages',
        meta: {
            notLogin: true
        }
    },
    {
        id: 'me-payments',
        title: 'Payments',
        type: 'basic',
        link: '/me-payments',
        meta: {
            isCustomer: true
        }
    },
    {
        id: 'wallets',
        title: 'Wallets',
        type: 'basic',
        link: '/wallets',
        meta: {
            isCustomer: true
        }
    },
];
