<form class="flex flex-col w-full "
      [formGroup]="form">
    <div class="w-full flex custom-file-container align-items-center" [ngClass]="{'m-0': withoutMarge}">
        <!--        <div class="imagePreview col-lg-12" *ngIf="fileUrl && fileUrl !== ''">-->
        <!--            <img [src]="fileUrl" class="w-100" [alt]="userForm.value.name">-->
        <!--        </div>-->
        <ng-container *ngIf="!fileUrl; else imagePath">
            <input type="file" class="custom-file-input d-none" [id]="id"
                   [disabled]="disabled"
                   [multiple]="multi"
                   [accept]="typeAccept" (change)="saveImage($event)"/>
            <div class="flex flex-col w-full">
                <span class="label">
                {{label}}
                </span>
                <label class="custom-file-label position-relative w-60 cursor-pointer"
                       [ngClass]="{'w-full': !labelStaticWidth}"
                       [for]="id">
                    Choose file
                </label>
                <mat-error *ngIf="form.get(field).touched && form.get(field).invalid">
                    {{_formUtilsService.getErrorMessage(form, field)}}
                </mat-error>
            </div>
            <mat-progress-spinner *ngIf="loader"
                                  class="mt-6"
                                  [diameter]="24"
                                  [mode]="'indeterminate'"></mat-progress-spinner>
        </ng-container>
        <ng-template #imagePath>
            <div class="flex align-items-center justify-content-between cursor-pointer position-relative">
                <img [src]="fileUrl"
                     alt="image-url" class="small-image"
                     (click)="viewImage(fileUrl)">
                <ng-container *ngIf="showDeleteIcon">
                    <mat-icon [svgIcon]="'close'"
                              matTooltip="Delete image"
                              class="remove-icon"
                              (click)="deleteImage()">
                    </mat-icon>
                </ng-container>
                <ng-container *ngIf="showDelete">
                    <button type="button"
                            mat-raised-button
                            class="my-5"
                            color="warn"
                            (click)="deleteImage()">
                        Delete image
                    </button>
                </ng-container>
            </div>
        </ng-template>
    </div>
</form>
