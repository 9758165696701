import {Injectable, NgZone} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {UserService} from '../../layout/common/user/user.service';
import getBrowserFingerprint from 'get-browser-fingerprint';

import {map} from 'rxjs/operators';
import {isObject} from 'rxjs/internal-compatibility';
import {isString} from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class ZLoggerService {

    url: string = environment.loggerApiUrl + '/api/client/public/logs';
    ip: any;

    telemetries = [];
    subtypes = ['button', 'submit', 'hidden', 'checkbox', 'radio'];

    window = window;
    private httpClient: HttpClient;

    /**
     * Constructor
     */
    constructor(private handler: HttpBackend,
                private userService: UserService,
                private ngZone: NgZone) {

        this.httpClient = new HttpClient(handler);
        this.getIp();
        this.subscribeEvent();
    }

    subscribeEvent(): void {
        this.ngZone.run(() => {
            this.window.onload = () => {
                if (this.window.addEventListener) {
                    this.window.addEventListener('click', this.eventClick.bind(this), true);
                    this.window.addEventListener('blur', this.eventBlur.bind(this), true);
                }
            };
        });
    }

    eventClick(event): void {
        const element = this.getEventElement(event);
        if (element && element.tagName && (this.checkEventElement(element, 'a') || this.checkEventElement(element, 'button') || this.checkEventElement(element, 'input', ['button', 'submit']))) {
            this.prepareEvent('click', element);
        } else if (this.checkEventElement(element, 'input', ['checkbox', 'radio'])) {
            this.prepareEvent('input', element, element.value);
        }
    }

    eventBlur(event): void {
        const element = this.getEventElement(event);
        if (element && element.tagName) {
            if (element.className && element.className.includes('jodit-wysiwyg')) {
                this.prepareEvent('input', element, element.innerText);
            } else if (this.checkEventElement(element, 'textarea')) {
                this.prepareEvent('input', element, element.value);
            } else if (this.checkEventElement(element, 'select')) {
                this.inputSelectChanged(element);
            } else if (this.checkEventElement(element, 'MAT-SELECT')) {
                this.prepareEvent('input', element, element.innerText);
            } else if (this.checkEventElement(element, 'input') && !this.checkEventElement(element, 'input', this.subtypes)) {
                this.prepareEvent('input', element, element.value);
            }
        }
    }

    inputSelectChanged(elem): void {
        if (elem.multiple) {
            elem.options.map(el => {
                if (el?.selected) {
                    this.prepareEvent('input', elem, el.value);
                }
            });
        } else if (elem.selectedIndex >= 0 && elem.options[elem.selectedIndex]) {
            this.prepareEvent('input', elem, elem.options[elem.selectedIndex].value);
        }
    }

    prepareEvent(subtype, element, value?): void {
        const v = value && value.length > 300 ? value.slice(0, 300) : value;
        const e = {
            subtype,
            element: this.getElementLabel(element),
            value: v
        };
        if (this.telemetries?.length) {
            const lastTelemetry = this.telemetries[this.telemetries.length - 1].body;
            if (lastTelemetry.value === value && e.element === lastTelemetry.element) {
                return;
            }
        }
        this.setTelemetry('dom', 'info', 'dom event', e);
    }

    getElementLabel(element): string {
        let label = element.localName;
        if (element?.id) {
            label += '#' + element.id;
        }
        if (element?.className?.length) {
            const classList = element.className.split(' ');
            classList.map(className => {
                if (className.includes('ng-tns') || className.includes('jodit-wysiwyg')) {
                    label += '.' + className;
                }
            });
        }
        return label;
    }

    getElementType(event): string {
        return (event.getAttribute('type') || '').toLowerCase();
    }

    checkEventElement(element, type?, subTypes?): any {
        if (element.tagName.toLowerCase() !== type.toLowerCase()) {
            return false;
        }
        if (!subTypes) {
            return true;
        }
        element = this.getElementType(element);
        let isSubType = false;
        subTypes.map(subType => {
            if (subType === element) {
                isSubType = true;
            }
        });
        return isSubType;
    }

    getEventElement(event): any {
        if (event.target) {
            return event.target;
        }
        if (this.window.document && this.window.document.elementFromPoint) {
            return this.window.document.elementFromPoint(event.clientX, event.clientY);
        }
        return null;
    }

    // subscribeForm(form: FormGroup | FormControl, subscriber?: any): void {
    //     this.setTelemetry('log', 'info', 'Subscribe form change');
    //     let value = null;
    //     form.valueChanges.pipe(
    //         debounceTime(500),
    //         takeUntil(subscriber),
    //         tap(res => {
    //             if (JSON.stringify(value) === JSON.stringify(res) || !value) {
    //                 value = res;
    //                 return;
    //             }
    //             value = res;
    //             this.setTelemetry('dom', 'info', 'Change form value', res);
    //         })
    //     ).subscribe();
    // }

    checkHiddenFields(res): any {
        const hiddenFields = ['password', 'password_confirmation'];
        const dontSendField = ['x-api-key', 'Authorization'];
        if (res && isObject(res)) {
            Object.keys(res).forEach(fieldName => {
                if (res[fieldName] && isObject(res[fieldName])) {
                    res[fieldName] = this.checkHiddenFields(res[fieldName]);
                }
                if (hiddenFields.includes(fieldName) && res[fieldName] && isString(res[fieldName])) {
                    res[fieldName] = res[fieldName].replace(/[\s\S]/g, '*');
                }
                if (dontSendField.includes(fieldName) && res[fieldName]) {
                    delete res[fieldName];
                }
            });
        }
        return res;
    }

    // type - 'log', 'network', 'dom', 'error', 'routing
    // level - (critical,error,warning,info,debug)
    // message - string
    // data - any

    setTelemetry(type: string, level: string = 'debug', message: string, data?): void {
        const telemetry = {
            type,
            timestamp: +Date.now(),
            level,
            source: 'client',
            body: {},
        };
        let body = {
            message,
            url: window.location.href
        };
        if (type === 'network' && data) {
            this.setNetworkInfo(body, data);
        }
        if (type === 'routing' && data && data.urlKey) {
            body['url'] = data.urlKey;
        }
        if (type === 'dom') {
            body = {...body, ...data};
        }
        if (type === 'error' && data) {
            if (data.id) {
                body['log_id'] = data.id;
            }
            if (data.name && data.name === 'HttpErrorResponse') {
                this.setNetworkInfo(body, data);
            }
        }
        telemetry.body = body;

        if (this.telemetries && this.telemetries.length >= 20) {
            this.telemetries.splice(0, 1);
        }
        this.telemetries.push(telemetry);
    }

    setNetworkInfo(body, data): void {
        body['url'] = data.url;
        if (data.status) {
            body['status'] = data.status;
        }else{
            body['status'] = 400;
        }
        body['start_time'] = data.start_time;
        body['end_time'] = data.end_time;
        body['request'] = this.getRequestData(data);
    }

    error(type, error): void {
        const message = this.getMessage(error);
        this.setTelemetry('error', 'error', message, error);
        const data = this.getLog('error', error);
        this.send(data);
    }

    info(message): void {
        const data = this.getLog('info', {error: {message}});
        this.send(data);
    }

    warning(message): void {
        const data = this.getLog('warning', {error: {message}});
        this.send(data);
    }

    critical(message): void {
        const data = this.getLog('critical', {error: {message}});
        this.send(data);
    }

    debug(error): void {
        const message = this.getMessage(error) || 'Error';
        this.setTelemetry('error', 'debug', message, error);
        const data = this.getLog('debug', error);
        this.send(data);
    }

    getLog(level: string, error?: any): LogType {
        const data: LogType = {
            level,
            url: window.location.href,
            request: this.getRequestData(error),
            traceback: error && error.traceback || [],
            message: this.getMessage(error),
            telemetries: this.telemetries,
            client: {
                useragent: this.getUserAgent(),
                fingerprint: this.getFingerPrint(),
                ip: this.ip,
            }
        };
        if (this.userService.user && this.userService.user.id && this.userService.user.email) {
            data['user'] = {
                id: this.userService.user.id,
                email: this.userService.user.email
            };
        }
        return data;
    }

    getRequestData(error): any {
        const data = {};
        if (error.method) {
            data['method'] = error.method;
        }
        if (error.body) {
            data['data'] = this.checkHiddenFields(error.body);
        }
        if (error.response) {
            data['response'] = this.checkHiddenFields(error.response);
        }
        if (error.headers) {
            data['headers'] = {};
            const keys = error.headers.keys();
            if (keys?.length) {
                keys.map(key => {
                    data['headers'][key] = error.headers.get(key);
                });
                this.checkHiddenFields(data['headers']);
            }
        }
        if (error.params) {
            data['params'] = {};
            const keys = error.params.keys();
            if (keys?.length) {
                keys.map(key => {
                    data['params'][key] = error.params.get(key);
                });
                this.checkHiddenFields(data['params']);
            }
        }
        return data;
    }

    getUserAgent(): any {
        return this.window.navigator.userAgent;
    }

    getFingerPrint(): any {
        return getBrowserFingerprint();
    }

    getIp(): any {
        if (!this.window.location.href.includes('localhost')) {
            this.httpClient.get('https://api.ipify.org/?format=json').subscribe(res => {
                    this.ip = res;
                }
            );
        }
    }

    getMessage(error): string {
        let message = '';
        if (error?.error?.error && typeof error.error.error === 'string') {
            message = error.error.error;
        } else if (error?.error && typeof error?.error === 'string') {
            message = error.error;
        } else {
            message = error.message || '';
        }
        return message;
    }

    send(data: LogType): void {
        const headerConfig = {
            'x-api-key': environment.loggerToken,
            'Accept': 'application/json'
        };
        // this.httpClient.post<any>(this.url, data, {headers: headerConfig}).pipe(
        //     map(res => res.data))
        //     .subscribe(res => {
        //         this.setTelemetry('error', 'error', res['message'], res);
        //     });
    }
}

export interface LogType {
    level: string;
    url: string;
    request: {};
    traceback: [];
    message: string;
    user?: {
        id: string | number;
        email: string
    };
    client: {
        useragent: string
        fingerprint: string
        ip: string
    };
    telemetries: any[];
}
