<form class="flex w-full align-items-center justify-content-center"
      [formGroup]="dateForm">
    <mat-form-field class="w-full" (click)="picker.open()">
        <mat-label>{{title}}</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker"
               placeholder="Choose a date"
               [formControlName]="field"
               (dateChange)="changeFormatDate($event)"
               [min]="minDate"
               readonly>
        <mat-datepicker-toggle matSuffix
                               [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker
                                 [showSeconds]="true" [color]="'primary'">
        </ngx-mat-datetime-picker>
        <mat-error *ngIf="dateForm.get(field).invalid">
            {{_formUtilsService.getErrorMessage(dateForm, field)}}
        </mat-error>
    </mat-form-field>
    <ng-container *ngIf="showClearIcon && dateForm.get(field).value">
        <button class="previous-button mt-2 ml-1"
                mat-icon-button
                (click)="clearField(dateForm.get(field))">
            <mat-icon svgIcon="mat_outline:close"></mat-icon>
        </button>
    </ng-container>
</form>

