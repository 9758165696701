import {
    Component,
    Input,
    OnInit,
    EventEmitter,
    ChangeDetectorRef,
} from '@angular/core';
import {FormUtilsService} from '../../../core/utils/form-utils.service';

import moment from 'moment';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {

    @Input() dateForm;
    @Input() field;
    @Input() title;
    @Input() showClearIcon = false;
    @Input() minDate;

    @Input() checkValidation = new EventEmitter();

    constructor(public _formUtilsService: FormUtilsService,
                private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.dateForm.get(this.field).value?.length) {
            const date = moment(this.dateForm.get(this.field).value).format('YYYY-MM-DD HH:mm:ss');
            this.dateForm.get(this.field).setValue(date);
        }
    }

    clearField(field: any): void {
        field.setValue('');
    }

    changeFormatDate(event: any): void {
        this.dateForm.get(this.field).setValue(moment(event.value).format('YYYY-MM-DD HH:mm:ss'));
    }

    checkValidationPicker(): void {
        this.dateForm.get(this.field).markAsTouched();
        this.dateForm.get(this.field).updateValueAndValidity();
        this._changeDetectorRef.markForCheck();
    }

}
