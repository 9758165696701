import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[swipe]'
})
export class SwipeDirective {

    @Output() swipeLeft: EventEmitter<any> = new EventEmitter<any>();
    @Output() swipeRight: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    defaultTouch = { x: 0, y: 0, time: 0 };

    @HostListener('touchstart', ['$event'])
    @HostListener('touchend', ['$event'])
    @HostListener('touchcancel', ['$event'])
    handleTouch(event): void {
        const touch = event.touches[0] || event.changedTouches[0];

        // check the events
        if (event.type === 'touchstart') {
            this.defaultTouch.x = touch.pageX;
            this.defaultTouch.y = touch.pageY;
            this.defaultTouch.time = event.timeStamp;
        } else if (event.type === 'touchend') {
            const deltaX = touch.pageX - this.defaultTouch.x;
            const deltaY = touch.pageY - this.defaultTouch.y;
            const deltaTime = event.timeStamp - this.defaultTouch.time;

            // simulte a swipe -> less than 500 ms and more than 60 px
            if (deltaTime < 500) {
                // touch movement lasted less than 500 ms
                if (Math.abs(deltaX) > 60) {
                    // delta x is at least 60 pixels
                    if (deltaX > 0) {
                        this.doSwipeRight();
                    } else {
                        this.doSwipeLeft();
                    }
                }

                // if (Math.abs(deltaY) > 60) {
                //     // delta y is at least 60 pixels
                //     if (deltaY > 0) {
                //         this.doSwipeDown(event);
                //     } else {
                //         this.doSwipeUp(event);
                //     }
                // }
            }
        }
    }

    doSwipeLeft(): void {
        this.swipeLeft.emit(true);
    }

    doSwipeRight(): void {
        this.swipeRight.emit(true);
    }

    // doSwipeUp(event): void {
    //     console.log('swipe up', event);
    // }
    //
    // doSwipeDown(event): void {
    //     console.log('swipe down', event);
    // }
}
