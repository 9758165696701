import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Input, Output, EventEmitter
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {PackagesService} from '../../../modules/main/services/packages.service';


@Component({
    selector: 'app-packages-select',
    templateUrl: './packages-select.component.html'
})
export class PackagesSelectComponent implements OnInit, OnDestroy {

    @Input() form;
    @Input() field = 'good_id';
    @Input() multi = false;
    @Input() showClearIcon = false;

    showAutoLoading = false;
    isItemsScroll = false;
    isResetItems = false;
    errorLoadPackages = false;

    params = {
        name: '',
        page: null,
        withUserCounts: 1
    };


    @Output() changed?: any = new EventEmitter();
    items$: Observable<any>;

    // Private
    private _unsubscribeAll = new Subject<any>();

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param _packagesService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _packagesService: PackagesService
    ) {
    }

    ngOnInit(): void {
        this.getItems();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        if (this.isResetItems) {
            this._packagesService.resetItems();
        }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    autocompleteItems(value: string): void {
        this.isItemsScroll = false;
        if ((this.params.name && this.params.name !== '' && value === '' && (!this.params.page || this.params.page !== 1)) || value && value.length) {
            this.params.name = value;
            this.getItems(true);
        }
    }

    scrollItems(event: any): void {
        if (event && this.params.page) {
            this.isItemsScroll = true;
            this.getItems(true);
        }
    }

    getItems(isLoad = false): void {
        this.items$ = this._packagesService.packages$;
        const params = {...{}, ...this.params};
        if (this._packagesService.packages?.length && !isLoad) {
            this.showAutoLoading = false;
            return;
        }
        this.showAutoLoading = true;
        this._changeDetectorRef.markForCheck();
        if (!params.name) {
            delete params.name;
        }
        if (!params.page) {
            delete params.page;
        } else {
            this.isResetItems = true;
        }
        this._packagesService.getItems(params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this.showAutoLoading = false;
                this.errorLoadPackages = false;
                const page = ++res.data.current_page <= res.data.last_page ? res.data.current_page : null;
                page ? this.params.page = page : delete this.params.page;
                this._changeDetectorRef.markForCheck();
            }, error => {
                this.showAutoLoading = false;
                this.errorLoadPackages = true;
                this._changeDetectorRef.markForCheck();
            });
    }

    selected(event): void {
        if (event && event.close) {
            return;
        }
        const item = this._packagesService.packages.find(el => el.id === event);
        this.changed.emit(item);
    }
}
