import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';

import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../environments/environment';
import {COMMON} from '../../shared/contstants/common.const';

@Injectable({
    providedIn: 'root'
})
export class StorageUtilsService {

    private _changeSiteSection: BehaviorSubject<boolean | null>;

    /**
     * Constructor
     */
    constructor(private _cookieService: CookieService,
                private _titleService: Title) {

        this._changeSiteSection = new BehaviorSubject(false);
    }

    urlDependingValue(val?: any): string {
        let url = '';
        switch (true) {
            case val === 1: {
                url = this.urlPublic();
                break;
            }
            case val === 2: {
                url = this.urlEmpty();
                break;
            }
            case val === 3: {
                url = this.urlProfile();
                break;
            }
            default : {
                url = this.urlWithBackend();
                break;
            }
        }
        return url;
    }


    urlEmpty(): string {
        return environment.apiUrl + '/api';
    }

    urlPublic(): string {
        return environment.apiUrl + '/api/public';
    }

    urlProfile(): string {
        return environment.apiUrl + '/api/profile';
    }

    urlWithBackend(): string {
        return `${environment.apiUrl}/api/backend`;
    }

    clear(): void {
        this._cookieService.deleteAll();
    }

    check(field): any {
        return this._cookieService.check(COMMON.COOKIE_KEY + field);
    }

    set(field: string, data: any): void {
        this._cookieService.set(COMMON.COOKIE_KEY + field, JSON.stringify(data), null, '/');
    }

    get(field: string): any {
        return JSON.parse(this._cookieService.get(COMMON.COOKIE_KEY + field));
    }

    // CONFIG SITE

    setConfig(data: any): void {
        sessionStorage['config'] = JSON.stringify(data);
        this.checkConfig();
    }

    checkConfig(): void {
        const config = this.config;
        if (config && config.name) {
            environment.currentSiteName = config.name;
            this._titleService.setTitle(config.name);
        }
    }

    resetConfig(): void {
        delete sessionStorage['config'];
    }

    get config(): any {
        if (sessionStorage['config']) {
            return JSON.parse(sessionStorage['config']);
        }
    }
}

