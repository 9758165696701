import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {COMMON} from '../../../shared/contstants/common.const';

@Injectable({
    providedIn: 'root'
})
export class LayoutsService {

    private _configs: BehaviorSubject<any | null>;


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
    ) {
        // Set the private defaults
        this._configs = new BehaviorSubject([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get configs$(): Observable<any[]> {
        return this._configs.asObservable();
    }

    get configs(): any[] {
        return this._configs.getValue();
    }

    config(field): any {
        const configs = this._configs.getValue();
        if (!configs?.length) {
            return null;
        }
        const config = configs.find(el => el.key === field);
        if (!config?.value) {
            return null;
        }
        return config.value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getUrl(id?: string | number, addToUrl?: string | number): string {
        let url = `${environment.apiUrl}/api/public/configs`;
        if (!!id) {
            url = url + `/${id}`;
        }
        if (!!addToUrl) {
            url = url + `/${addToUrl}`;
        }
        return url;
    }

    getConfig(): Observable<any> {
        return this._httpClient.get<any>(this.getUrl()).pipe(
            tap((res) => {
                if (res?.data?.length) {
                    res.data.map(el => {
                        if (el?.key === 'layouts') {
                            const value = JSON.parse(el.value);
                            this._configs.next(value);
                        }
                    });
                }
            })
        );
    }

    updateConfig(data): Observable<any> {
        return this._httpClient.post<any>(`${environment.apiUrl}/api/backend/configs`, data).pipe(
            tap((res) => {
                if (res?.data?.value) {
                    const value = JSON.parse(res.data.value);
                    this._configs.next(value);
                }
            })
        );
    }

    getBackground(configKey, field?: string, fieldTwo?: string): any {
        if (!this.config(configKey) || !this.config(configKey)[field]) {
            return '';
        }
        if (this.config(configKey)[field] && this.config(configKey)[field]?.image?.length) {
            return `center / cover no-repeat url(${this.config(configKey)[field].image})`;
        }
        if (this.config(configKey)[field] && this.config(configKey)[field][fieldTwo]?.image?.length) {
            return `center / cover no-repeat url(${this.config(configKey)[field][fieldTwo].image})`;
        }
        if (this.config(configKey)[field] && this.config(configKey)[field]?.color?.length) {
            return this.config(configKey)[field].color;
        }
        if (this.config(configKey)[field] && this.config(configKey)[field][fieldTwo]?.color?.length) {
            return this.config(configKey)[field][fieldTwo].color;
        }
        return '';
    }

    getLogo(): string {
        if (this.config('global')?.header?.logo) {
            return this.config('global').header.logo || COMMON.LOGO_PATCH;
        }
        return COMMON.LOGO_PATCH;
    }


}
