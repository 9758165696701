import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatMenuModule} from '@angular/material/menu';

import {ActionNotificationModule} from './components/action-natification/action-notification.module';
import {UploadImageModule} from './components/upload-image/upload-image.module';
import {ViewImageModule} from './components/view-image/view-image.module';
import {TextEditorModule} from './components/text-editor/text-editor.module';
import {BackButtonModule} from './components/back-button/back-button.module';
import {PackagesSelectModule} from './components/packages-select/packages-select.module';

import {CheckScrollDirective} from './directive/check-scroll.directive';
import {EmbedVideoService} from './services/video.service';

import {StripHtmlPipe} from './pipe/strip-html.pipe';
import {CustomSelectModule} from './components/custom-select/custom-select.module';
import {DateTimePickerModule} from './components/datetimepicker/date-time-picker.module';
import {IframeResizerDirective} from './directive/iframe-resizer.directive';
import {SafeUrlPipe} from './pipe/safe-url.pipe';
import {DateRangePickerModule} from './components/daterangepicker/date-range-picker.module';
import {ImageEditorModule} from './components/image-editor/image-editor.module';
import {FabMenuModule} from './components/mat-fab-menu/fab-menu.module';
import {WalletsSelectModule} from './components/wallets-select/wallets-select.module';
import {SwipeDirective} from './directive/swipe.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UploadImageModule,
        ViewImageModule,
        TextEditorModule,
        MatButtonToggleModule,
        MatMenuModule,
        BackButtonModule,
        CustomSelectModule,
        DateTimePickerModule,
        PackagesSelectModule,
        DateRangePickerModule,
        ImageEditorModule,
        FabMenuModule,
        WalletsSelectModule,
    ],
    declarations: [
        CheckScrollDirective,
        StripHtmlPipe,
        SafeUrlPipe,
        IframeResizerDirective,
        SwipeDirective
    ],
    providers: [
        EmbedVideoService,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ActionNotificationModule,
        CheckScrollDirective,
        UploadImageModule,
        ViewImageModule,
        TextEditorModule,
        BackButtonModule,
        StripHtmlPipe,
        CustomSelectModule,
        DateTimePickerModule,
        IframeResizerDirective,
        SafeUrlPipe,
        PackagesSelectModule,
        DateRangePickerModule,
        FabMenuModule,
        SwipeDirective
    ]
})
export class SharedModule {
}
