import {
    Component,
    Input,
    OnInit,
    EventEmitter,
    ChangeDetectorRef, ViewChild, Output, OnDestroy, AfterViewInit,
} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {FormControl, Validators} from '@angular/forms';
import {debounceTime, filter, takeUntil, tap} from 'rxjs/operators';
import {fromEvent, Observable, Subject} from 'rxjs';

import {FormUtilsService} from '../../../core/utils/form-utils.service';

import _ from 'lodash';

@Component({
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('matSelectMulti') matSelMulti: MatSelect;

    @Input() selectForm;
    @Input() formField: string;
    @Input() label: string;
    @Input() multi = false;
    @Input() fieldForTitle: string | string[];
    @Input() isAutoOpen = false;
    @Input() sendCloseEvent = false;
    @Input() required = true;
    @Input() showToggleAllButton = true;
    @Input() showChannelsInName = false;
    @Input() items: any;
    @Input() showAutoLoading = false; // loading внутри селектора (не тот что рядом)
    @Input() isStrangeFormat = false;
    @Input() showClearIcon = false;
    @Input() showSearchField = true;
    @Input() isGroup = false;
    @Input() showAddCategory = false;
    @Input() showPlaceholder = false;
    @Input() groupField: string;
    @Input() currentId: string | number; // для того чтобы скрить текущий елемент
    @Input() isItemsScroll = false; // пока работает только для масива async
    @Input() isInfinityScroll = false; // пока работает только для масива async
    @Input() isAutocomplete = false; // пока работает только для масива async
    @Input() createdCategorySubscriber; // для получения ивента создания формы

    @Output() changed?: any = new EventEmitter();
    @Output() createdCategoryEvent?: any = new EventEmitter();
    @Output() infiniteScroll = new EventEmitter<boolean>(); // пока работает только для масива async
    @Output() autocomplete = new EventEmitter<string>(); // пока работает только для масива async

    allSelected = false;
    search = new FormControl(null);
    name = new FormControl(null, Validators.required);
    selectedItems: any[] = [];

    arrayItemsEmpty = false;
    isShowCategoryForm = false;
    selectedGroupId = null;
    placeholder = null;
    allItems: any[] = [];

    private panel: Element;
    private _unsubscribeAll = new Subject<boolean>();

    constructor(public _formUtilsService: FormUtilsService,
                private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.placeholder = null;
        if (this.showPlaceholder) {
            this.placeholder = this.label;
        }
        if (this.isAutoOpen) {
            this.showAutoLoading = false;
            this.setSelectedItems([]);
            this._changeDetectorRef.detectChanges();
            this.matSelMulti.toggle();
        }
        this.search.setValue(null);
        this.subscribeSearch();
        this.showAutoLoading = false;
        this.arrayItemsEmpty = false;
        this.checkItems();
    }

    checkItems(): void {
        if (this.items && this.items.length) {
            this.selectedItems = Object.assign([], this.items);
        } else if (this.items instanceof Observable) {
            this.items
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(res => {
                    if (this.isStrangeFormat) {
                        return this.prepareFormatData(res);
                    }
                    this.allItems = res;
                    if (this.isItemsScroll) {
                        this.selectedItems = this.selectedItems.concat(res);
                    } else {
                        this.setSelectedItems(res);
                    }
                    this._changeDetectorRef.markForCheck();
                });
        } else {
            this.selectedItems = [];
        }
    }

    prepareFormatData(res: any): void {
        const items = [];
        if (res) {
            this.selectForm.get(this.formField).enable();
            Object.keys(res).map(key => {
                items.push(key);
            });
        } else {
            this.items = [];
        }
        this.setSelectedItems(items);
    }

    setSelectedItems(items): void {
        if (items && items.length) {
            this.selectedItems = [...[], ...items];
            this.arrayItemsEmpty = false;
        } else {
            this.selectedItems = [null];
            this.arrayItemsEmpty = true;
        }
        this._changeDetectorRef.markForCheck();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        if (this.isInfinityScroll) {
            this.subscribeMatSelectOpen();
        }
    }

    opened(isOpen: boolean): void {
        if (!isOpen) {
            if (this.sendCloseEvent) {
                this.changed.emit({close: true});
            }
            this.selectForm.get(this.formField).enable();
            this.selectedGroupId = null;
            this.isShowCategoryForm = false;
            if (this.items && this.items.length) {
                return this.selectedItems = Object.assign([], this.items);
            }
            if (this.isAutocomplete && !!this.search.value) {
                this.search.setValue(null);
                return this.autocomplete.next('');
            }
            this.search.setValue(null);
            if (this.isGroup) {
                return this.checkItems();
            }
        }
    }

    toggleAllSelection(): any {
        this.allSelected = !this.allSelected;  // to control select-unselect
        let items = [];
        if (this.allSelected) {
            this.matSelMulti.options.forEach((item: MatOption) => {
                if (item.value || item.value === 0) {
                    items.push(item.value);
                }
            });
        } else {
            this.showClearIcon = false;
            items = [];
        }
        this.selectForm.get(this.formField).setValue(items);
        this.checkedData(items);
        this._changeDetectorRef.markForCheck();
    }

    subscribeSearch(): void {
        this.search.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                filter(value => value !== null),
                tap((value) => {
                    if (this.isAutocomplete) {
                        this.autocomplete.next(value);
                    } else {
                        if (this.items && this.items.length) {
                            this.selectedItems = this._formUtilsService.selectFilter(this.items, value);
                        } else if (this.allItems && this.allItems.length) {
                            this.selectedItems = this._formUtilsService.selectFilter(this.allItems, value);
                        }
                        if (this.selectedItems.length === 0) {
                            this.arrayItemsEmpty = true;
                            this.selectedItems = [null];
                        } else {
                            this.arrayItemsEmpty = false;
                        }
                        this._changeDetectorRef.markForCheck();
                    }
                }),
            ).subscribe();
    }

    getValue(item): any {
        if (item?.code && typeof item.code === 'string') {
            return item.code;
        }
        return item.id || item.id === 0 ? item.id : item;
    }

    checkedData(event: any): void {
        if (this.changed) {
            if (this.isGroup && !this.multi) {
                const option = this.matSelMulti;
                const el = {
                    name: option.triggerValue,
                    id: option.value
                };
                this.changed.emit(el);
            } else {
                this.changed.emit(event);
            }
        }
    }

    subscribeMatSelectOpen(): void {
        this.matSelMulti.openedChange.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((opened) => {
            if (opened) {
                this.panel = this.matSelMulti.panel.nativeElement;
                this.registerScrollListener();
            }
        });
    }

    registerScrollListener(): void {
        fromEvent(this.panel, 'scroll').pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(500),
            tap((event) => {
                this.onScroll(event);
            })
        ).subscribe();
    }

    onScroll($event): void {
        const element = $event.srcElement;
        const checkScroll = (element.scrollHeight - element.scrollTop) < (element.clientHeight + 10);
        if (checkScroll && !this.showAutoLoading) {
            this.infiniteScroll.emit(true);
        }
    }

    showUsersCount(item: any): boolean {
        return typeof item.users_count === 'number' && typeof item.active_users_count === 'number';
    }

    clearField(field: any): void {
        field.setValue('');
    }

    // Adding category and subcategory

    // showCategoryForm(id): void {
    //     this.resetNameControl();
    //     this.selectForm.get(this.formField).disable();
    //     if (id) {
    //         this.selectedGroupId = id;
    //         this.isShowCategoryForm = false;
    //     } else {
    //         this.isShowCategoryForm = true;
    //         this.selectedGroupId = null;
    //     }
    //     this._changeDetectorRef.markForCheck();
    // }
    //
    // hideCategoryForm(): void {
    //     this.isShowCategoryForm = false;
    //     this.selectedGroupId = null;
    //     this.selectForm.get(this.formField).enable();
    //     this.resetNameControl();
    //     this._changeDetectorRef.markForCheck();
    // }

    // addCategory(id?: number | string): void {
    //
    //     if (this.name.invalid || this.name.value.trim() === '') {
    //         this.name.setValue('');
    //         this.name.markAsTouched();
    //         this.name.setErrors({required: true});
    //         return this._changeDetectorRef.markForCheck();
    //     }
    //
    //     const data = {
    //         id: id,
    //         name: this.name.value
    //     };
    //     this.createdCategoryEvent.next(data);
    //     this.createdCategorySubscriber.subscribe(res => {
    //             this.selectForm.get(this.formField).enable();
    //             if (res.error) {
    //                 this.name.setErrors({required: true});
    //             } else {
    //                 if (!res.parent_id && res.id) {
    //                     this.selectedGroupId = res.id;
    //                     this.selectForm.get(this.formField).disable();
    //                 } else {
    //                     this.selectedGroupId = null;
    //                 }
    //                 this.isShowCategoryForm = false;
    //             }
    //             this.resetNameControl();
    //             this._changeDetectorRef.markForCheck();
    //         }
    //     );
    // }

    getTitle(item): string {
        if (this.fieldForTitle?.length && typeof this.fieldForTitle !== 'string') {
            let text = '';
            this.fieldForTitle.map((field, index) => {
                if (_.has(item, field) && _.get(item, field, '')) {
                    text += _.get(item, field, '');
                    if (index === 0) {
                        text += ' - ';
                    }
                }
            });
            return text;
        }
        if (_.has(item, this.fieldForTitle)) {
            return _.get(item, this.fieldForTitle, '');
        }
        return item;
    }

    resetNameControl(): void {
        this.name.setValue('');
        this.name.setErrors(null);
        this.name.markAsUntouched();
    }

}
