import {Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TreoMediaWatcherService} from '@treo/services/media-watcher';
import {TreoNavigationService} from '@treo/components/navigation';
import {environment} from '../../../../../environments/environment';
import {StorageUtilsService} from '../../../../core/utils/storage.utils.service';
import {LayoutsService} from '../../../../modules/main/services/layouts.service';
import {MatMenu} from "@angular/material/menu";
import {TreoAnimations} from "../../../../../@treo/animations";

@Component({
    selector: 'centered-layout',
    templateUrl: './centered.component.html',
    styleUrls: ['./centered.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: TreoAnimations
})
export class CenteredLayoutComponent implements OnInit, OnDestroy {

    currentSiteName = environment.currentSiteName;

    data: any;
    isScreenSmall: boolean;

    @ViewChild('header', {read: ElementRef}) header: ElementRef;
    @ViewChild('scrollArrow', {read: ElementRef}) scrollArrow: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {Router} _router
     * @param _storageUtilsService
     * @param {TreoMediaWatcherService} _treoMediaWatcherService
     * @param {TreoNavigationService} _treoNavigationService
     * @param _layoutsService
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _storageUtilsService: StorageUtilsService,
        private _treoMediaWatcherService: TreoMediaWatcherService,
        private _treoNavigationService: TreoNavigationService,
        public _layoutsService: LayoutsService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the resolved route data
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
        });

        // Subscribe to media changes
        this._treoMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the breakpoint is 'lt-md'
                this.isScreenSmall = matchingAliases.includes('lt-md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param key
     */
    toggleNavigation(key): void {
        // Get the navigation
        const navigation = this._treoNavigationService.getComponent(key);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    changeClassForHeader(event): void {
        if (event) {
            if (event.scrollTop > 300) {
                this.header.nativeElement.classList.add('is-fixed');
            } else {
                this.header.nativeElement.classList.remove('is-fixed');
            }
            if (event.scrollTop > 200) {
                this.header.nativeElement.classList.add('is-small');
            } else {
                this.header.nativeElement.classList.remove('is-small');
            }
            if (event.scrollTop > 800) {
                this.scrollArrow.nativeElement.classList.add('show');
            } else {
                this.scrollArrow.nativeElement.classList.remove('show');
            }
        }
    }

    scrollTop(): void {
        const scrollToTop = window.setInterval(() => {
            const pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 50); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 16);
    }

}
