import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PackagesSelectComponent} from './packages-select.component';
import {CustomSelectModule} from '../custom-select/custom-select.module';

@NgModule({
    declarations: [
        PackagesSelectComponent
    ],
    imports: [
        CommonModule,
        CustomSelectModule,
        MatProgressSpinnerModule
    ],
    exports: [
        PackagesSelectComponent
    ]
})
export class PackagesSelectModule {
}
