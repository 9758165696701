export const COMMON: any = {
    CHANNEL_TYPES: ['mail', 'sms', 'telegram'],
    NOTIFICATIONS: ['waiting', 'processing', 'completed', 'cancelled'],
    NOTIFICATIONS_ITEMS: ['waiting', 'processing', 'completed', 'cancelled', 'read', 'delivered', 'pending'],
    PAYMENT_STATUSES: ['failed', 'refunded', 'paid', 'processing', 'unpaid', 'declined', 'forbidden', 'cancelled', 'dispute'],
    NOTIFICATIONS_FOR_USERS: ['pending', 'processing', 'delivered', 'failed', 'cancelled', 'read'],
    COOKIE_KEY: ['GA_config'],

    LOGO_PATCH: 'assets/images/logo/logo.png',

    PRIORITIES: [{id: 0, name: 'low'}, {id: 1, name: 'medium'}, {id: 2, name: 'high'}],

    TABLE: {
        PARAMS: {
            'created_at[orderBy]': 'desc'
        },
        PAGINATOR: {
            PAGE_SIZE: 15,
            PAGE_OPTIONS: [5, 15, 50, 100, 200, 500]
        },
        SORT: {
            DIRECTIONS: [
                {
                    id: 'asc',
                    name: 'Ascending'
                },
                {
                    id: 'desc',
                    name: 'Descending'
                }
            ]
        }

    }
};
