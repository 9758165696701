import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';

import {DateRangePickerComponent} from './date-range-picker.component';

@NgModule({
    declarations: [
        DateRangePickerComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        FormsModule,
        NgxDaterangepickerMd.forRoot(),
        ReactiveFormsModule,
        MatButtonModule,
    ],
    exports: [
        DateRangePickerComponent
    ]
})
export class DateRangePickerModule {
}
