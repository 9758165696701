<!-- Navigation -->
<treo-vertical-navigation class="bg-indigo-800 theme-dark"
                          [appearance]="'futuristic'"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.default"
                          [opened]="!isScreenSmall">

    <div treoVerticalNavigationContentHeader>
        <div class="logo">
            <a href="/" class="cursor-pointer">
                <img src="assets/images/logo/logo.png" alt="logo">
            </a>
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header justify-content-between">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Spacer -->
        <!--        <div class="spacer"></div>-->

        <div class="logo h-full">
            <a href="/" class="cursor-pointer h-full">
                <img src="assets/images/logo/logo.png" alt="logo">
            </a>
        </div>

        <user></user>
    </div>

    <!-- Content -->
    <div class="content">
        <mat-progress-bar [mode]="'indeterminate'" value="40" *ngIf="loading"></mat-progress-bar>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <div class="footer">
        <ng-container *ngIf="_layoutsService.config('global')?.footer?.text?.length; else defaultText">
            <span [innerHTML]="_layoutsService.config('global').footer.text"></span>
        </ng-container>
        <ng-template #defaultText>
           Vegas Dave NFT {{currentYear}}
        </ng-template>

        <p class="font-bold">
            <span class="flex xs:flex-wrap links">
                <a routerLink="/pages/privacypolicy" target="_blank" class="pr-2">Privacy policy</a>
                <div class="border-footer h-full xs:d-none"></div>
                <a routerLink="/pages/terms" target="_blank">Terms and Conditions of Use</a>
            </span>
        </p>
    </div>

</div>
