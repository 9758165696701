<!-- Button -->
<ng-container *ngIf="user && user.id; else singInButton">
    <button class="icon-button"
            mat-icon-button
            [matMenuTriggerFor]="userActions">
    <span class="avatar">
        <img *ngIf="showAvatar && user?.photo"
             [src]="user.photo">
        <mat-icon *ngIf="!showAvatar || !user?.photo"
                  [svgIcon]="'account_circle'"></mat-icon>
    </span>
    </button>
    <mat-menu class="user-actions-menu"
              [xPosition]="'before'"
              #userActions="matMenu">
        <button mat-menu-item>
        <span class="user-info">
            <span>Signed in as</span>
            <span class="email">{{user?.email}}</span>
        </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item [routerLink]="profileLink">
            <mat-icon [svgIcon]="'settings'"></mat-icon>
            <span>Account details</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item
                (click)="signOut()">
            <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
            <span>Sign out</span>
        </button>
    </mat-menu>
</ng-container>
<ng-template #singInButton>
         <span class="avatar">
            <a class="flex items-center sign-in" [href]="'auth/sign-in'">
                Sign in
            </a>
        </span>
</ng-template>
