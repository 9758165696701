import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {ImageEditorComponent} from './image-editor.component';
import {ToastUiImageEditorModule} from 'ngx-tui-image-editor';

@NgModule({
    declarations: [
        ImageEditorComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ToastUiImageEditorModule,
    ],
    exports: [
        ImageEditorComponent
    ]
})
export class ImageEditorModule {
}
