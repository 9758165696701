import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {StorageUtilsService} from '../../../core/utils/storage.utils.service';


@Injectable({
    providedIn: 'root'
})
export class WalletsService {
    // Private
    private _items: BehaviorSubject<any[] | null>;

    private _pagination: BehaviorSubject<number | null>;


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param _storageUtilsService
     */
    constructor(
        private _httpClient: HttpClient,
        private _storageUtilsService: StorageUtilsService
    ) {
        // Set the private defaults
        this._items = new BehaviorSubject([]);

        this._pagination = new BehaviorSubject(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get items$(): Observable<any[]> {
        return this._items.asObservable();
    }

    get items(): any[] {
        return this._items.getValue();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<number> {
        return this._pagination.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getUrl(id?: string | number, addToUrl?: string | number): string {
        let url = `${this._storageUtilsService.urlDependingValue()}/wallets`;
        if (!!id) {
            url = url + `/${id}`;
        }
        if (!!addToUrl) {
            url = url + `/${addToUrl}`;
        }
        return url;
    }

    resetItems(): void {
        this._items.next([]);
    }

    getItems(params?: any): Observable<any> {
        return this._httpClient.get<any>(this.getUrl(), {
            params: params
        }).pipe(
            tap((res) => {
                this._items.next(res.data.data);
                this._pagination.next(res.data.total);
            })
        );
    }

    getMeItems(params?: any): Observable<any> {
        return this._httpClient.get<any>(`${this._storageUtilsService.urlDependingValue(3)}/wallets`, {
            params: params
        }).pipe(
            tap((res) => {
                this._items.next(res.data.data);
                this._pagination.next(res.data.total);
            })
        );
    }

    getById(id: number): Observable<any> {
        return this._httpClient.get<any>(this.getUrl(id)).pipe(
            map((res) => res.data)
        );
    }

    getSignature(): Observable<any> {
        return this._httpClient.get<any>(`${this._storageUtilsService.urlDependingValue(3)}/wallets/signature`).pipe(
            map((res) => res.data)
        );
    }

    sendResult(data): Observable<any> {
        return this._httpClient.post<any>(`${this._storageUtilsService.urlDependingValue(3)}/wallets`, data).pipe(
            map((res) => {
                const items = this.items;
                items.unshift(res.data);
                this._items.next(items);
                this.updatePagination(+1);
                return res.data;
            })
        );
    }

    createEmpty(): Observable<any> {
        return this.items$.pipe(
            take(1),
            map((items) => {
                const newItem: any = {
                    id: null,
                    isCreating: true,
                };
                this._items.next([newItem, ...items]);

                this.updatePagination(+1);

                return newItem;
            })
        );
    }

    create(data: any): Observable<any> {
        return this.items$.pipe(
            take(1),
            switchMap(items =>
                this._httpClient.post<any>(this.getUrl(), data).pipe(
                    map((response) => {
                            const created = response.data;
                            const index = items.findIndex(item => item.isCreating);
                            items[index] = created;

                            this._items.next(items);

                            return created;
                        }
                    )))
        );
    }

    createWithModal(data: any): Observable<any> {
        return this.items$.pipe(
            take(1),
            switchMap(items =>
                this._httpClient.post<any>(this.getUrl(), data).pipe(
                    map((response) => {
                            const created = response.data;

                            this._items.next([created, ...items]);

                            return created;
                        }
                    )))
        );
    }

    update(data: any): Observable<any> {
        return this.items$.pipe(
            take(1),
            switchMap(items =>
                this._httpClient.patch<any>(this.getUrl(data.id), data).pipe(
                    map((response) => {
                            const updated = response.data;
                            const index = items.findIndex(item => item.id === data.id);
                            items[index] = updated;

                            this._items.next(items);
                            return updated;
                        }
                    )
                ))
        );
    }

    delete(id: number): Observable<boolean> {
        return this.items$.pipe(
            take(1),
            switchMap(items =>
                this._httpClient.delete<any>(this.getUrl(id)).pipe(
                    map((bol) => {

                        const index = items.findIndex(item => item.id === id);

                        items.splice(index, 1);

                        this._items.next(items);

                        this.updatePagination(-1);
                        return true;
                    })
                ))
        );
    }

    cancelCreate(): Observable<boolean> {
        return this.items$.pipe(
            take(1),
            map((items) => {

                const index = items.findIndex(item => item.isCreating);

                items.splice(index, 1);

                this._items.next(items);

                this.updatePagination(-1);

                return true;
            })
        );
    }

    updatePagination(val: number): void {
        this.pagination$.pipe(take(1)).subscribe(res => {
            const data = res + val;
            this._pagination.next(data);
        });
    }

}
