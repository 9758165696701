// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import { MatFabMenuModule} from '@angular-material-extensions/fab-menu';

import {FabMenuComponent} from './fab-menu.component';


@NgModule({
    declarations: [
        FabMenuComponent
    ],
    imports: [
        CommonModule,
        MatFabMenuModule
    ],
    exports: [
        FabMenuComponent
    ]
})
export class FabMenuModule {
}
