<!-- Navigation -->
<treo-vertical-navigation class="bg-cool-gray-900 theme-dark"
                          *ngIf="isScreenSmall"
                          [appearance]="'classic'"
                          [mode]="'over'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.horizontal"
                          [opened]="false">

    <div treoVerticalNavigationContentHeader>
        <div class="logo">
            <a routerLink="/" class="cursor-pointer">
                <img src="assets/images/logo/logo.png" alt="logo">
            </a>
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper is_dark" appCheckScroll [returnScrollElement]="true" (scrollEmit)="changeClassForHeader($event)">

    <!-- Header -->
    <div #header class="header header_1 header_2 style2">

        <div class="container justify-content-between">

            <div class="logo h-full">
                <a routerLink="/" class="cursor-pointer h-full">
                    <img src="assets/images/logo/logo.png" alt="logo">
                </a>
            </div>

            <!-- Horizontal navigation -->
            <treo-horizontal-navigation *ngIf="!isScreenSmall"
                                        [name]="'mainNavigation'"
                                        [navigation]="data.navigation.horizontal"></treo-horizontal-navigation>
            <!-- Navigation toggle button -->
            <button class="navigation-toggle-button"
                    *ngIf="isScreenSmall"
                    mat-icon-button
                    (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'menu'"></mat-icon>
            </button>

            <!-- Logo -->
            <!--            <ng-container *ngIf="!isScreenSmall">-->

            <!--            </ng-container>-->


            <!-- User -->
            <user></user>

        </div>

    </div>

    <!-- Content -->
    <div class="content">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="footer">
        <div class="container justify-content-between w-full flex">
            <ng-container *ngIf="_layoutsService.config('global')?.footer?.text?.length; else defaultText">
                <span [innerHTML]="_layoutsService.config('global').footer.text"></span>
            </ng-container>
            <ng-template #defaultText>
                Vegas Dave NFT {{currentYear}}
            </ng-template>
            <p class="font-bold">
            <span class="flex xs:flex-wrap links">
                <a routerLink="/pages/privacypolicy" target="_blank" class="pr-2">Privacy policy</a>
                <div class="border-footer h-full xs:d-none"></div>
                <a routerLink="/pages/terms" target="_blank">Terms and Conditions of Use</a>
            </span>
            </p>
        </div>
    </div>
    <a id="scroll-top" #scrollArrow (click)="scrollTop()"></a>
</div>


