import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';

import {environment} from '../../../environments/environment';

import {UserService} from '../../layout/common/user/user.service';
import {StorageUtilsService} from '../utils/storage.utils.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {
    // Private
    _authenticated: boolean;

    private url: string = environment.apiUrl;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param permissionsService
     * @param userService
     * @param _storageUtilsService
     * @param _router
     */
    constructor(
        private _httpClient: HttpClient,
        private permissionsService: NgxPermissionsService,
        private userService: UserService,
        private _storageUtilsService: StorageUtilsService,
        private _router: Router
    ) {
        // Set the defaults
        this._authenticated = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem(environment.authTokenKey, token);
    }

    get accessToken(): string {
        return localStorage.getItem(environment.authTokenKey);
    }

    get hasAccessToken(): boolean {
        return this.accessToken && !!this.accessToken.length;
    }

    removeToken(key: string): void {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getSiteConfig(): Observable<any> {
        return this._httpClient.get(this.url + '/api').pipe(
            map(res => {
                    this._storageUtilsService.setConfig(res['data']);
                    return of(res['data']);
                }
            ));
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: any): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError({isAlreadyLogin: true});
        }

        return this._httpClient.post(this.url + '/api/login', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.access_token;

                // Set the authenticated flag to true
                this._authenticated = true;
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signUp(credentials: any): Observable<any> {
        return this._httpClient.post(this.url + '/api/public/registration', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.access_token;

                // Set the authenticated flag to true
                this._authenticated = true;
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    resetPassword(credentials: any): Observable<any> {
        return this._httpClient.post(this.url + '/api/public/password_reset_request ', credentials);
    }

    recoveryPassword(data, hash: any): Observable<any> {
        return this._httpClient.post(this.url + '/api/public/password_reset/' + hash, data);
    }

    verifyEmail(hash: any): Observable<any> {
        return this._httpClient.get(this.url + '/api/public/verify/email/' + hash);
    }

    sendEmailVerifyRequest(): Observable<any> {
        return this._httpClient.post(this.url + '/api/profile/verify_email_request', {});
    }

    /**
     * get permissions
     */
    getProfilePermissions(): any {
        return this._httpClient.get<any>(this.url + '/api/profile/me/permissions').pipe(
            map(res => {
                this.permissionsService.loadPermissions(res.data);
                return res.data;
            }),
            catchError(error => {
                this._authenticated = false;
                return throwError(error);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        this.removeToken(environment.authTokenKey);
        this.permissionsService.flushPermissions();
        this.userService.removeUser();
        this._storageUtilsService.clear();
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (this.hasAccessToken) {
            this._authenticated = true;
            return of(true);
        }

        return of(false);
    }

    checkPermissions(): void {
        const permissions = this.permissionsService.getPermissions();
        if (permissions?.length) {
            this.navigate(permissions);
        }
        this.getProfilePermissions().subscribe(res => {
            this.navigate(res);
        });
    }

    navigate(res): void {
        if (res && res.length) {
            const isAdmin = res.includes('manage roles and permissions');
            // Navigate to the redirect url
            const redirectURL = isAdmin ? '/backend' : '';
            this._router.navigateByUrl(redirectURL);
        } else {
            this._router.navigateByUrl('');
        }
    }
}
